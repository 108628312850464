import React from "react";
import { Checkbox, Button } from "antd";
import "./index.less";

const CheckboxGroup = Checkbox.Group;
const IconFont = Loader.loadBaseComponent("IconFont");
const SearchInput = Loader.loadBaseComponent("SearchInput");

export default class SelectList extends React.Component {
  onChange = values => {
    this.props.cancelChecked(values);
  };
  onCheckAllChange = e => {
    const { list, cancelChecked } = this.props;
    cancelChecked && cancelChecked(e.target.checked ? list.map(v => v.id) : []);
  };
  gitIndeterminate = () => {
    const { list, cancelIds } = this.props;
    let checkStatus = {
      indeterminate:cancelIds.length && list.length && list.length !== cancelIds.length,
      checkAll:cancelIds.length && list.length && list.length === cancelIds.length
    }
    return checkStatus
  };
  render() {
    const { list, del, cancelIds, onChange, submit,value } = this.props;
    const checkStatus = this.gitIndeterminate();
    return (
      <div className="place-tree-select">
        <div className="th">
          <Checkbox
            indeterminate={checkStatus.indeterminate}
            checked={checkStatus.checkAll}
            onChange={this.onCheckAllChange}
          />
          场所列表
          <SearchInput
            className="place-search"
            placeholder="请输入您想搜索的内容"
            enterButton
            defaultValue={value}
            onChange={(v) => onChange(v)}
          />
        </div>
        <div className="select-wrapper">
          <div className="lm-place-select-list">
            <CheckboxGroup onChange={this.onChange} value={cancelIds}>
              {list.map(v => (
                <div className="p-item">
                  <Checkbox value={v.id} />
                  <IconFont type="icon-L_Place_Place" />
                  {v.areaName}
                  {/* <IconFont 
                    className='close'
                    // onClick={del && del}
                    onClick={(e) => del && del(v.id)}
                    type='icon-S_Photo_MarkNo'
                  /> */}
                </div>
              ))}
            </CheckboxGroup>
          </div>
        </div>
        <div className="footer-sure">
          <Button type="default" onClick={submit} disabled={!cancelIds.length}>
            取消分配
          </Button>
        </div>
      </div>
    );
  }
}
